<template>
  <div class="container">
    <BasicTitle title="Nueva Noticia" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Título *" />
      <BorderInput
        v-model="notice.data.title"
        label="Título *"
        :disabled="notice.disabled"
        @keyup.enter="onSave"
      />
      <FormError :show="notice.rules.title" message="Ingrese un título" />
      <BasicLabel label="Cuerpo del mensaje *" />
      <OutlinedTextArea
        v-model="notice.data.text"
        :disabled="notice.disabled"
      />
      <FormError :show="notice.rules.text" message="Ingrese un texto" />
      <div class="link" v-if="!notice.disabled">
        <label class="link-label" for="indLink">Agregar link de noticia</label>
        <Checkbox id="indLink" value="" v-model="notice.data.indLink" />
      </div>
      <FormError />
      <div v-show="notice.data.indLink.length">
        <BasicLabel label="Link *" />
        <OutlinedTextArea
          v-model="notice.data.link"
          :disabled="notice.disabled"
        />
        <FormError :show="notice.rules.link" />
      </div>
      <!-- <BorderFile
        :v-model="notice.selectedDocuments"
        @select="onSelectFiles"
      />
      <FormError /> -->
    </div>
    <PrimaryButton v-if="!notice.disabled" label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import BorderDate from "../../widgets/calendar/BorderDate.vue";
import BorderTime from "../../widgets/calendar/BorderTime.vue";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea.vue";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode } from "../../constants";
import { validation, actions, aws } from "../../constants";
import BorderFile from "../../widgets/file/BorderFile.vue";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    BorderDate,
    BorderTime,
    FormError,
    PrimaryButton,
    BorderFile,
    OutlinedTextArea,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const notice = reactive({
      disabled: false,
      selectedDocuments: [],
      data: {
        id: -1,
        title: "",
        text: "",
        indLink: "",
        link: "",
      },
      rules: {
        title: false,
        text: false,
        link: false,
      },
    });

    const onSelectFiles = (ev) => {
      notice.selectedDocuments = ev.files;
    };

    const validate = () => {
      let valid = true;

      if (!notice.data.title) {
        notice.rules.title = true;
        valid = false;
      }
      if (!notice.data.text) {
        notice.rules.text = true;
        valid = false;
      }
      if (notice.data.indLink && !notice.data.link) {
        notice.rules.link = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const uploaded = async () => {
        const request = {
          id: notice.data.id,
          title: notice.data.title,
          text: notice.data.text,
          buildingId: store.state.general.selectedBuilding,
          link: notice.data.link,
        };

        /* let response = null;
        if (store.state.openMode === openMode.MODIFY) {
          response = await store.dispatch(
            actions.noticeActions.update,
            request
          );
        } else {

        } */
        const response = await store.dispatch(
          actions.noticeActions.create,
          request
        );
        store.commit("setLoading", false);
        if (response.ok) {
          store.commit("setSelectedNotices", []);
          router.push("/noticias/listado");
        } else {
          alert(response.message);
        }
      };

      if (notice.selectedDocuments.length) {
        const ext = validation.extensionRegex.exec(
          notice.selectedDocuments[0].name
        )[0];
        const name = `${notice.data.name}${ext}`;
        key = `${notice.data.name}/images/${name}`;
        aws.uploadFile(key, notice.selectedDocuments[0], uploaded);
      } else {
        uploaded();
      }
    };

    onBeforeMount(() => {
      if (!store.state.openMode) router.push("/noticias/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      if (store.state.openMode === openMode.DETAIL) {
        const selected = store.state.notice.selectedNotices[0];
        notice.data.id = selected.id;
        notice.data.title = selected.title;
        notice.data.text = selected.text;
        notice.data.indLink = selected.link ? "true" : "";
        notice.data.link = selected.link;
        notice.disabled = true;
      }
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (notice.data.title) notice.rules.title = false;
      if (notice.data.text) notice.rules.text = false;
      if (!notice.data.indLink || notice.data.link) notice.rules.link = false;
    });

    return { store, notice, onSave, onSelectFiles };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.link-label {
  margin-right: 1rem;
  font-family: "klavika";
  color: var(--primary);
}
</style>
